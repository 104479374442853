/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";

const images = [
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fmicroblading-big.jpg?alt=media&token=c93f4db9-a086-4462-a094-9a2742ecb1f6",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Ffacial-treatment-small.jpg?alt=media&token=8225fe59-9362-40d7-a88a-7e8ddeb99434",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2FeminenseCover.JPG?alt=media&token=56e7b2f4-dbf7-4286-92ea-2d6ffb5d7006",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2FoxygeneoF.JPG?alt=media&token=db8afc0f-4018-437b-9f1d-346bbed2e8ab",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fhydrafacialcover.JPG?alt=media&token=176c0fd5-fbbc-4645-9fe8-d0efeadf2ab7",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fhairlasercover.jpg?alt=media&token=5e544f9b-a591-4ede-ab7a-f9045764d8f0",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fcashmeramaskcover.jpg?alt=media&token=6bede5da-eedb-47ac-861c-16295878f185",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fmicroneedlingcover.jpg?alt=media&token=93d68e9d-6941-48d5-b613-cab5fb029d05",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fderma.jpg?alt=media&token=a3fdb5a8-f6ef-4ed7-aaf3-640675474953",
];

const useStyles = makeStyles({
  root: {
    maxWidth: 450,
    flex: "1 1 480px",
    marginTop: "1rem",
    boxShadow: "6px 5px 27px 6px rgba(30, 30, 60, 0.31)",
  },
  media: {
    height: 450,
  },
  title: {
    textAlign: "center",
    backgroundColor: "#FBEEE6",
    color: "#e1315a",
  },
  cardactions: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "#ffe5d8",
  },
});

function MediaCard(props) {
  const classes = useStyles();

  const handleMediaCardClick = () => {
    let string = props.serviceName.split(" ").join("");
    string = string.toLocaleLowerCase();
    props.history.push(string);
  };

  const handleBookNowClick = () => {
    props.history.push("/appointments");
  };

  return (
    <Card className={classes.root}>
      <CardHeader className={classes.title} title={props.serviceName} />
      <CardActionArea>
        <CardMedia
          onClick={handleMediaCardClick}
          className={classes.media}
          image={images[props.index % images.length]}
          title={props.serviceName}
        />
      </CardActionArea>

      <CardActions className={classes.cardactions}>
        <Button onClick={handleBookNowClick} size="large" color="primary">
          BOOK NOW
        </Button>
      </CardActions>
    </Card>
  );
}

export default withRouter(MediaCard);
