/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
import classNames from "classnames";
import CrossButton from "./CrossButton";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Typography from "@material-ui/core/Typography";

const img1 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Ffacialtreatment1.jpg?alt=media&token=b9611ca4-cdfe-445f-9ffb-1ff562c28967";
const img2 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Ffacialtreatment2.jpg?alt=media&token=41b0f303-8432-4a51-a3b5-059974ef097f";
const img3 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Ffacialtreatment3.jpg?alt=media&token=d6be1b33-aef7-44e7-ba86-81ab8418cc1a";
const img4 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Ffacialtreatment4.jpg?alt=media&token=a2fd0615-ec5a-4734-a4ac-9e02fc629b4f";

function FacialTreatment_Service(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, classes.mainRaised)}>
      <div
        style={{
          minHeight: "1000px",
        }}
      >
        <CrossButton />

        <Typography align="center" variant="h3" style={{ color: "#676504" }}>
          Facial Treatment
        </Typography>

        <div className="mygrid2">
          <div
            style={{
              padding: "2rem",
              margin: "1rem",
              gridArea: "textformat",
            }}
          >
            <Typography variant="body1" style={{ color: "#676504" }}>
              A facial treatment is a relaxing cleansing process performed by a
              licensed esthetician that exfoliates, hydrates, and nourishes the
              skin. Facials are a gentle, relaxing treatment that is perfect for
              the majority of skin types. During the treatment, the face is
              usually steamed, cleaned, and exfoliated.
            </Typography>

            <div style={{ paddingTop: "2rem" }}>
              <Button
                onClick={() => props.history.push("/appointments")}
                variant="contained"
                color="secondary"
                endIcon={<ArrowRightAltIcon fontSize="large" />}
              >
                Book Now
              </Button>
            </div>
          </div>

          <div className="img4container">
            <div
              style={{ backgroundImage: `url(${img1})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img2})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img3})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img4})` }}
              className="img4s"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(FacialTreatment_Service);
