/* eslint-disable react/prop-types */
import React from "react";

function EmployeeIndex(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        width: "auto",
        minWidth: "250px",
        backgroundColor: "white",
        boxShadow: "6px 5px 27px 6px rgba(30, 30, 60, 0.31)",
        justifyContent: "start",
        alignItems: "center",
        padding: "1rem",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          height: "50px",
          width: "50px",
          borderRadius: "5px",
          backgroundColor: `${props.employee.color}`,
        }}
      ></div>
      <div>{props.employee.name}</div>
    </div>
  );
}

export default function EmployeeIndexs(props) {
  const comps = props.employees.map((emp) => (
    <EmployeeIndex key={emp.id} employee={emp} />
  ));
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: "15px",
        flexWrap: "wrap",
        padding: "2rem 0",
      }}
    >
      {comps}
    </div>
  );
}
