import { ADD_EVENT , REMOVE_EVENT , ADD_SERVICE_FOR_APPOINTMENT, REMOVE_SERVICE_FOR_APPOINTMENT, ADD_SERVICE, REMOVE_SERVICE, RESET_APPOINTMENT } from './actions'

import { combineReducers} from 'redux'

 const eventReducer = (state = [] , action ) => {     
    switch (action.type ) {
        case ADD_EVENT : return state.concat(action.payload)
        case REMOVE_EVENT : return state.filter ( event => event.id !== action.payload.id)
        default : return state
    }
}

/**
 * 
 * these are reducers which wont scale more than 5 or 10 objects in the array but are made to safely and easily implement the booking system 
 * 
 */
const serviceReducer = (state = [] , action) => {
    switch (action.type) {
        case ADD_SERVICE : return state.concat(action.payload)
        case REMOVE_SERVICE: return state.filter ( service => service.name !== action.payload.name)
        default: return state
    }
}

// appointments have an id 
const appointmentReducer = (state = [] , action) => {
    switch (action.type) {
        case ADD_SERVICE_FOR_APPOINTMENT : return  state.concat(action.payload)
        case REMOVE_SERVICE_FOR_APPOINTMENT: {
             return state.filter ( appointment => appointment.uuid !== action.payload.uuid )
        }
        case RESET_APPOINTMENT : return []
 

        default: return state

    }
    
}

export default combineReducers({
    events: eventReducer,
    appointments: appointmentReducer,
    services: serviceReducer 
})
