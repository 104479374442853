/*eslint-disable*/
import React from "react";
//import StoreIcon from '@material-ui/icons/Store';
import EventIcon from '@material-ui/icons/Event';

// react components for routing our app without refresh
import { withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons

// <ListItem className={classes.listItem}>
// <CustomDropdown
//   noLiPadding
//   buttonText="Stores"
//   buttonProps={{
//     className: classes.navLink,
//     color: "transparent"
//   }}
//   buttonIcon={StoreIcon}
//   dropdownList={[
//     <Link to="/surrey" className={classes.dropdownLink}>
//       Surrey
//     </Link>,
  
//   ]}
// />
// </ListItem>


// core components
//import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

 function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
      <Tooltip
          title="Book an Appointment"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
        <Button
          onClick={ ()=> props.history.push("/appointments")}

          color="transparent"
         
          className={classes.navLink}
        >
          <EventIcon className={classes.icons} /> Appointments

        </Button>
        </Tooltip>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/pages/category/Beauty-Salon/Orchid-11-Day-Spa-Laser-Center-240326179778925/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/orchid11medspa/?hl=en"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}

export default withRouter(HeaderLinks)