/* eslint-disable react/no-unescaped-entities */
import React from "react";
import CardServices from "./CardServices";
import Typography from "@material-ui/core/Typography";
import { MyMapComponent } from "./GoogleMapsComponent";
import FrontPageInfo from "./FrontPageInfo";
import Carousal from "./Carousal";

function LeftServices() {
  return (
    <div style={{ flex: "1 1 700px" }} className="left-service-container">
      <div className="flex-c">
        <div style={{}}>
          <div className="container-b">
            <div style={{ padding: "1rem" }}>
              <h1>
                {" "}
                <b>
                  WHAT <span style={{ color: "#676504" }}> WE </span> DO{" "}
                </b>{" "}
              </h1>
            </div>
            <div style={{ padding: "1rem" }} className="clr-combo-container">
              <div
                style={{
                  borderTopLeftRadius: "8px",
                  borderBottomLeftRadius: "8px",
                }}
                className="clr-cmbo"
              ></div>
              <div
                style={{ backgroundColor: "#676504" }}
                className="clr-cmbo"
              ></div>
              <div
                style={{
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
                className="clr-cmbo"
              ></div>
            </div>
            <div
              className="responsibleP"
              style={{ paddingTop: "4rem", padding: "1rem", height: "auto" }}
            >
              <Typography variant="body1">
                Orchid 11 Med Spa has been passionately serving the community of
                Abbotsford for the last 4 years. Owned and operated by a local
                business woman who is persuing her childhood aspirations. She
                went to Blanche Macdonald Centre and did numerous courses like
                nail parlour, all body laser, micro blading, hydro facial.
              </Typography>
              <Typography variant="body1">
                Harpreet has since then put her amazing skills to use to provide
                world class service to her customers. Her team's vision is to
                make her customers look confident, feel beautiful and be
                flawless.{" "}
              </Typography>
            </div>
          </div>
          <div className="container">
            <div>
              <span>
                <b> WHAT</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Services() {
  return (
    <div className="services-container">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div>
          <LeftServices />
        </div>

        <div
          className="responsive-height"
          style={{
            width: "700px",
            flex: "1 1 700px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxShadow: "6px 5px 27px 6px rgba(30, 30, 60, 0.31)",
            backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fstaff2.jpg?alt=media&token=34607fdb-e2a8-4b62-9423-a83e62561674"})`,
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "1rem",
        }}
      >
        <div style={{ flex: "1 1 500px" }}>
          <MyMapComponent isMarkerShown />
        </div>
        <div style={{ flex: "1 1 500px" }}>
          <FrontPageInfo />
        </div>
      </div>
      <Carousal />

      <CardServices />
    </div>
  );
}
