import React from "react";
import ReactDOM from "react-dom";


import App from './App'

// pages for this product


ReactDOM.render(
  <App />,
  document.getElementById("root")
);
