import React from "react";
import Calender from "./Calender";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
import CustomizedSteppers from "./MaterialStepper";
//import EventsAddingForm from './PrivateAuthCompoents/EventsAddingForm'
import ChooseService from "./ChooseService";
import Confirmation from "./Confirmation";
import useMedia from "use-media";

/**
 * this is a function which manages 3 pages on the web
 *
 * 1. shows the calender so that user can select a appointment slot         appointment slot is saved in the store
 * 2. the customer then selects a service optionally a staff membet (not implemented yet)   this is also saved in the store
 * 3. the customer then confirms everyrthing gives an email and gets a confirmation
 *
 */

function CalenderHolder() {
  const isWide = useMedia({ minWidth: "550px" });

  const [activeStep, setActiveStep] = React.useState(0);
  const [activeTime, setActiveTime] = React.useState({});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div
      className={
        isWide
          ? classNames(classes.main, classes.mainRaised)
          : classNames(classes.main)
      }
    >
      <div>
        <h1
          style={{
            color: "#676504",
            fontFamily: "cursive",
            textAlign: "center",
            paddingTop: "1rem",
          }}
        >
          Book Your Appointment here
        </h1>

        <div style={{ marginTop: "50px" }}>
          <CustomizedSteppers
            handleBack={handleBack}
            handleReset={handleReset}
            activeStep={activeStep}
          />
          {activeStep === 1 ? (
            <div>
              <Calender handleNext={handleNext} setActiveTime={setActiveTime} />
            </div>
          ) : (
            ""
          )}
          {activeStep === 0 ? (
            <div>
              <ChooseService
                handleBack={handleBack}
                activeTime={activeTime}
                handleNext={handleNext}
              />
            </div>
          ) : (
            ""
          )}

          {activeStep === 2 ? (
            <div>
              <Confirmation handleReset={handleReset} activeTime={activeTime} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default CalenderHolder;
