/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
import classNames from "classnames";
import CrossButton from "./CrossButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const img2 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Flaser.jpg?alt=media&token=5c843927-1527-4691-9eb5-7b4097382e90";
const img1 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Flaser1.jpg?alt=media&token=6fe59e7a-ad01-4838-9843-e09276c2caec";
const img3 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Flaser3.jpeg?alt=media&token=3391f355-a6cd-458e-a85d-83822e28a08f";
const img4 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fhairlasertreatment41.jpg?alt=media&token=80c1932c-9c43-4aae-b04d-39e2fc986450";

function HairLaserTreatment_Service(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, classes.mainRaised)}>
      <div
        style={{
          minHeight: "1000px",
        }}
      >
        <CrossButton />

        <Typography align="center" variant="h3" style={{ color: "#676504" }}>
          Hair Laser Treatment
        </Typography>

        <div className="mygrid2">
          <div
            style={{
              padding: "2rem",
              margin: "1rem",
              gridArea: "textformat",
            }}
          >
            <Typography variant="body1" style={{ color: "#676504" }}>
              Laser hair removal is one of the most commonly done cosmetic
              procedures in the U.S. It beams highly concentrated light into
              hair follicles. Pigment in the follicles absorb the light. That
              destroys the hair. Lasers are useful for removing unwanted hair
              from the face, leg, chin, back, arm, underarm, bikini line, and
              other areas. Lasers can selectively target dark, coarse hairs
              while leaving the surrounding skin undamaged. Lasers can
              selectively target dark, coarse hairs while leaving the
              surrounding skin undamaged.
            </Typography>
            <div style={{ paddingTop: "2rem" }}>
              <Button
                onClick={() => props.history.push("/appointments")}
                variant="contained"
                color="secondary"
                endIcon={<ArrowRightAltIcon fontSize="large" />}
              >
                Book Now
              </Button>
            </div>
          </div>

          <div className="img4container">
            <div
              style={{ backgroundImage: `url(${img2})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img1})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img3})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img4})` }}
              className="img4s"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(HairLaserTreatment_Service);
