class TimeHoursMins {
  constructor(seed) {
    this.seed = seed;
  }
  getHours() {
    if (this.seed > 59) return Math.floor(this.seed / 60);
    return 0;
  }
  // assuming 0 mins value will never be here
  getMins() {
    return Math.floor(this.seed % 60);
  }
  toString() {
    if (this.seed < 60) return `${this.seed} Minutes`;
    else if (this.seed % 60 === 0) return `${this.getHours()} Hour`;
    else return `${this.getHours()} Hour and ${this.getMins()} Minutes`;
  }
  getValueInMins() {
    return this.seed;
  }
  addValueToSeed(newSeed) {
    this.seed = this.seed + newSeed;
  }
}

export { TimeHoursMins };
