/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
import classNames from "classnames";
import CrossButton from "./CrossButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const img1 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fmask1.jpg?alt=media&token=cfd557d1-3161-4290-a857-d91c45e8e860";
const img2 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fmask2.jpg?alt=media&token=13c680a0-21af-490d-a109-72aefe17f643";
const img3 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fmask3.jpg?alt=media&token=2b26ad69-ff21-43cb-9592-e1b61fe878de";
const img4 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fmask4.jpg?alt=media&token=64205bdf-f419-49f7-a57c-84b89894a144";

function CashmeraMask(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, classes.mainRaised)}>
      <div
        style={{
          minHeight: "1000px",
        }}
      >
        <CrossButton />

        <Typography align="center" variant="h3" style={{ color: "#676504" }}>
          Cashmera Masks
        </Typography>

        <div className="mygrid2">
          <div
            style={{
              padding: "2rem",
              margin: "1rem",
              gridArea: "textformat",
            }}
          >
            <Typography variant="body1" style={{ color: "#676504" }}>
              Discover a new skin with Casmara Algae Peel-Off facial mask. These
              modelling Algae based mask form a plastic film that adapts itself
              perfectly to the facial muscle structure. As it solidifies, the
              mask acts as a vehicle to enhance penetration of the active
              substances previously applied and on its own actives.
            </Typography>

            <div style={{ paddingTop: "2rem" }}>
              <Button
                onClick={() => props.history.push("/appointments")}
                variant="contained"
                color="secondary"
                endIcon={<ArrowRightAltIcon fontSize="large" />}
              >
                Book Now
              </Button>
            </div>
          </div>

          <div className="img4container">
            <div
              style={{ backgroundImage: `url(${img1})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img2})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img3})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img4})` }}
              className="img4s"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CashmeraMask);
