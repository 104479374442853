import React from "react";
import PlaceIcon from "@material-ui/icons/Place";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ScheduleIcon from "@material-ui/icons/Schedule";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    maxWidth: 500,
    margin: "auto",
    textDecoration: "none",
    background: "white",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
    boxShadow: "6px 5px 27px 6px rgba(30, 30, 60, 0.31)",
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function FrontPageInfo() {
  const classes = useStyles();

  return (
    <div
      style={{
        margin: "1rem 0",
        padding: "1rem 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignContent: "center",
        minHeight: "400px",
      }}
    >
      <div className={classes.root}>
        <List component="nav" aria-label="secondary mailbox folders">
          <ListItem>
            <Typography variant="h3" color="secondary">
              Orchid 11 Med Spa
            </Typography>
          </ListItem>
          <Divider />

          <ListItemLink
            target="_blank"
            href="https://www.google.ca/maps/dir//Orchid11+Day+Spa+and+Laser+Center,+2686+Langdon+St+unit+2,+Abbotsford,+BC+V9M+2K2/@49.051086,-122.3326208,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x5484354f0c699609:0xf7672cafd4022314!2m2!1d-122.3304321!2d49.051086!3e0"
          >
            <ListItemIcon>
              <PlaceIcon color="primary" fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="2 - 2686 Langdon St, Abbotsford BC V9M 2K2" />
          </ListItemLink>
          <Divider />

          <ListItemLink href="tel:778-779-2265">
            <ListItemIcon>
              <PhoneIcon color="primary" fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="+1 (778) 779-2265" />
          </ListItemLink>
          <Divider />

          <ListItemLink href="mailto:orchid11medspa@gmail.com">
            <ListItemIcon>
              <EmailIcon color="primary" fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="orchid11medspa@gmail.com" />
          </ListItemLink>
          <Divider />

          <ListItem>
            <ListItemIcon>
              <ScheduleIcon color="primary" fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="10:00 A.M. - 07:00 P.M." />
          </ListItem>
          <Divider />
        </List>
      </div>
    </div>
  );
}
