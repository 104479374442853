import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const style = {
  margin: 0,
  top: "auto",
  right: 80,
  bottom: 80,
  left: "auto",
  position: "fixed",
};

function FabContinue(props) {
  const classes = useStyles();

  if (props.appointments.length > 0) {
    return (
      <div>
        <Fab
          onClick={props.handleNext}
          style={style}
          color="primary"
          variant="extended"
        >
          <CheckCircleIcon size="large" className={classes.extendedIcon} />
          {props.appointments.length} CHECKOUT
        </Fab>
      </div>
    );
  } else {
    return <div></div>;
  }
}
const mapThisComponentStateToProps = (state) => ({
  appointments: state.appointments,
});

export default connect(mapThisComponentStateToProps)(FabContinue);
