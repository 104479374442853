import React from "react";
import Services from "./Services";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
import useMedia from "use-media";

export default function MainPages() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const isWide = useMedia({ minWidth: "550px" });

  return (
    <div
      id="home"
      style={{ padding: "2.5rem 0" }}
      className={
        isWide
          ? classNames(classes.main, classes.mainRaised)
          : classNames(classes.main)
      }
    >
      <Services />
      <div className="powr-instagram-feed" id="d39c8f6f_1603427847"></div>
    </div>
  );
}
