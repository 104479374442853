/* eslint-disable react/prop-types */
import React from "react";
import FacialTreatment from "./FacialTreatment";
import HandFootTreatments from "./HandFootTreatments";
import OrchidPacage from "./OrchidPacage";
import BodyTreatment from "./BodyTreatment";
import FabContinue from "./Fab_Continue";
import EminenseOrganicFacials from "./EminenseOrganicFacials";

export default function ChooseService(props) {
  return (
    <div>
      <div
        style={{
          minHeight: "3000px",
        }}
      >
        <h1 style={{ textAlign: "center" }}> Choose Service </h1>
        <OrchidPacage />
        <EminenseOrganicFacials />
        <BodyTreatment />
        <FacialTreatment />
        <HandFootTreatments />
        <FabContinue handleNext={props.handleNext} />
      </div>
    </div>
  );
}

/*
 sample prop
        end: Fri Oct 30 2020 11:00:00 GMT-0700 (Pacific Da…}
        title:"Appointment Available"
        start:Fri Oct 30 2020 10:00:00 GMT-0700 (Pacific Daylight Time)
        end:Fri Oct 30 2020 11:00:00 GMT-0700 (Pacific Daylight Time)
        id:
*/
