/* eslint-disable react/prop-types */
import React from "react";
import uuid from "uuid";

import ValueIndividual from "./IndividualValue";

export default function Value(props) {
  const values = props.value.map((value) => (
    <ValueIndividual key={uuid()} info={value} />
  ));
  return <div className="valueContainer">{values}</div>;
}
