import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
import classNames from "classnames";
import CrossButton from "./CrossButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const img1 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fhydrofacial.JPG?alt=media&token=6ffb8a68-48ba-437c-9af3-d3b3d34125f4";
const img2 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fhydrofacial4.jpg?alt=media&token=2ca370ec-ff93-4963-89aa-4ac3abab5be6";
const img3 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fhydrofacial2.jpg?alt=media&token=6e5a27ca-3d36-42a0-8607-47ae15e049e5";
const img4 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fhydrofacial3.jpg?alt=media&token=79907961-4db5-4a15-b10d-aebfed53314c";

function Medi_Facials_Service(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, classes.mainRaised)}>
      <div
        style={{
          minHeight: "1000px",
        }}
      >
        <CrossButton />

        <Typography align="center" variant="h3" style={{ color: "#676504" }}>
          Hydra Facials
        </Typography>

        <div className="mygrid2">
          <div
            style={{
              padding: "2rem",
              margin: "1rem",
              gridArea: "textformat",
            }}
          >
            <Typography variant="body1" style={{ color: "#676504" }}>
              A HydraFacial is a treatment that uses patented technology (a
              medical-grade device) to cleanse, extract, and hydrate skin[2]. It
              clears dead skin cells, loosens pores, and preps them for
              extraction using a mix of glycolic acid, salicylic acid, and
              botanical extracts. Then, it uses a unique vacuum-powered
              extraction method to remove “gunk” from the skin in a virtually
              painless procedure.
            </Typography>
            <Typography variant="body1" style={{ color: "#676504" }}>
              It’s all made possible thanks to a double-helix vortex treatment
              wand. One chamber of the wand draws dead skin cells and dirt from
              the skin while the other pumps the skin full of soothing nutrients
              (hyaluronic acid, vitamin C, and growth factors). Basically, your
              skin is freshly cleared for deeper penetration. It’s essentially a
              peel, microdermabrasion, and hydrating mask all in one.
            </Typography>

            <div style={{ paddingTop: "2rem" }}>
              <Button
                onClick={() => props.history.push("/appointments")}
                variant="contained"
                color="secondary"
                endIcon={<ArrowRightAltIcon fontSize="large" />}
              >
                Book Now
              </Button>
            </div>
          </div>

          <div className="img4container">
            <div
              style={{ backgroundImage: `url(${img1})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img2})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img3})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img4})` }}
              className="img4s"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Medi_Facials_Service);
