import { container } from "assets/jss/material-kit-react.js";

const componentsStyle = {
  container,
  brand: {
    color: "#FFFFFF",
    textAlign: "left"
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 0"
  },
  main: {
    background: "#fef6ed",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"

  },
  confirmationPage: {
    minHeight:'400px',
    height: 'auto',
    maxWidth: '600px',
    margin: 'auto',
    padding: '1rem',                   
    background: '  linear-gradient(270deg, rgba(255,229,216,1) 25%, rgba(255,202,212,1) 50%, rgba(243,171,182,1) 75%)',
    backgroundSize: '400%',
    animation: 'bg-animation 20s infinite alternate' 
  },
  link: {
    textDecoration: "none"
  },
  textCenter: {
    textAlign: "center"
  }
};

export default componentsStyle;
