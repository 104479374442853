import React from "react";
import Store from "./app_state/store";
import { Provider } from "react-redux";
import "./App.css";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import FirebaseContext from "./app_state/FirebaseContext";
import Firebase from "./app_state/firebase";
// Service components for cards displayed on Main Pages
import Microblading from "./FunctionalComponents/Microblading_Service";
import FacialTreatment_Service from "./FunctionalComponents/FacialTreatment_Service";
import EminenceOrganicFacial from "./FunctionalComponents/EminenceOrganicFacial";
import HairLaserTreatment_Service from "./FunctionalComponents/HairLaserTreatment_Service";
import DermaPlanning_Service from "./FunctionalComponents/DermaPlanning_Service";
import OxygeneoFacial from "./FunctionalComponents/OxygeneoFacial";
import HydroFacial from "./FunctionalComponents/HydroFacial";
import CashmeraMask from "./FunctionalComponents/CashmeraMask";
import MicroneedlingService from "./FunctionalComponents/MicroneedlingService";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "./components/Header/HeaderLinks";
import styles from "assets/jss/material-kit-react/views/components.js";
// wriiten custom components
import MainPages from "./FunctionalComponents/MainPages";
import CalenderHolder from "./FunctionalComponents/CalenderHolder";
import ScrollToTop from "./FunctionalComponents/ScrollToTop";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import { Typography } from "@material-ui/core";
import Particles from "react-particles-js";
import { SnackbarProvider } from "notistack";
import useWindowSize from "./FunctionalComponents/useWindowSize";

var hist = createBrowserHistory();

function App() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const size = useWindowSize();

  return (
    <SnackbarProvider maxSnack={3}>
      <Router history={hist}>
        <ScrollToTop />
        <Provider store={Store}>
          <FirebaseContext.Provider value={new Firebase()}>
            <div className="App">
              <Header
                brand="Orchid 11 Med Spa"
                rightLinks={<HeaderLinks />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                  height: 400,
                  color: "white",
                }}
              />
              <Parallax image={require("assets/img/bg4.jpg")}>
                <div className={classes.container}>
                  <div
                    className="img4sly"
                    style={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Ftransparebtbackground.png?alt=media&token=bb508996-cb99-43f7-b404-bfc4ae5d0744"})`,
                      padding: size.width > 800 ? "3.5rem 0" : "1px",
                    }}
                  >
                    <Typography color="textPrimary" variant="h1">
                      Orchid 11 Med Spa
                    </Typography>
                    <Typography align="center" variant="h3" color="secondary">
                      Beauty is Life
                    </Typography>

                    <Typography align="right" variant="h2" color="secondary">
                      With Spa
                    </Typography>
                  </div>
                  <GridContainer>
                    <GridItem>
                      <Particles
                        name={"Multiple images"}
                        params={{
                          particles: {
                            shape: {
                              type: "images",
                              image: [
                                {
                                  src:
                                    "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fmicroneedlingcover.jpg?alt=media&token=93d68e9d-6941-48d5-b613-cab5fb029d05",
                                  height: 20,
                                  width: 20,
                                },
                                {
                                  src:
                                    "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fmicroneedlingcover.jpg?alt=media&token=93d68e9d-6941-48d5-b613-cab5fb029d05",
                                  height: 20,
                                  width: 20,
                                },
                              ],
                            },
                          },
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </div>
              </Parallax>

              <Switch>
                <Route exact path="/appointments" component={CalenderHolder} />
                <Route
                  exact
                  path="/facialtreatment"
                  component={FacialTreatment_Service}
                />
                <Route exact path="/hydrafacial" component={HydroFacial} />
                <Route exact path="/cashmeramask" component={CashmeraMask} />
                <Route
                  exact
                  path="/oxygeneofacial"
                  component={OxygeneoFacial}
                />
                <Route
                  exact
                  path="/eminenceorganicfacial"
                  component={EminenceOrganicFacial}
                />
                <Route
                  exact
                  path="/hairlasertreatment"
                  component={HairLaserTreatment_Service}
                />
                <Route
                  exact
                  path="/microneedling"
                  component={MicroneedlingService}
                />
                <Route
                  exact
                  path="/dermaplaning"
                  component={DermaPlanning_Service}
                />
                <Route exact path="/microblading" component={Microblading} />
                <Route path="/" component={MainPages} />
              </Switch>
              <Footer />
            </div>
          </FirebaseContext.Provider>
          ,
        </Provider>
      </Router>
    </SnackbarProvider>
  );
}

export default App;

/*
echo "# orchid11" >> README.md
git init
git add README.md
git commit -m "first commit"
git branch -M main
git remote add origin https://github.com/paramveer73/orchid11.git
git push -u origin main
…or push an existing repository from the command line
git remote add origin https://github.com/paramveer73/orchid11.git
git branch -M main
git push -u origin main
           <div className={classes.container}>
              <GridContainer>
                <GridItem>
                  <div className={classes.brand}>
                  <Typography variant='h1'  >
                      Orchid 11 Med Spa
                      </Typography>
                      <Typography align='center' variant='h3' color='secondary'>
                      Beauty is Life 
                      </Typography>
                        
                      <Typography align='center' variant='h2' color='secondary'>
                           With Spa
                      </Typography>
                  </div>
                </GridItem>
              </GridContainer>
   */
