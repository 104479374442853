/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
import classNames from "classnames";
import CrossButton from "./CrossButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const img1 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fdermaplanning.jpg?alt=media&token=1184da4e-b183-440c-bf42-6a2e20de7832";
const img2 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fdermaplanning1.jpg?alt=media&token=8fe8ee09-3de6-41e1-ac40-540091e7f915";
const img3 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fdermplanningq24.jpg?alt=media&token=0d6ef9a3-8015-4701-b121-98cbbd4ca6a6";
const img4 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fdermplanning25.JPG?alt=media&token=02652ebe-e322-4355-92f5-41bbbeec376f";

function DermaPlanning_Service(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, classes.mainRaised)}>
      <div
        style={{
          minHeight: "1000px",
        }}
      >
        <CrossButton />

        <Typography align="center" variant="h3" style={{ color: "#676504" }}>
          Dermaplaning
        </Typography>

        <div className="mygrid2">
          <div
            style={{
              padding: "2rem",
              margin: "1rem",
              gridArea: "textformat",
            }}
          >
            <Typography variant="body1" style={{ color: "#676504" }}>
              Dermaplaning is a skin treatment that uses an exfoliating blade to
              skim dead skin cells and hair from your face. It’s also called
              microplaning or blading. Dermaplaning aims to make your skin’s
              surface smooth, youthful, and radiant. This treatment claims to
              remove deep scarring from acne and uneven pockmarks on your skin.
              It’s also used to remove “peach fuzz,” the short, soft hairs on
              your face.
            </Typography>
            <div style={{ paddingTop: "2rem" }}>
              <Button
                onClick={() => props.history.push("/appointments")}
                variant="contained"
                color="secondary"
                endIcon={<ArrowRightAltIcon fontSize="large" />}
              >
                Book Now
              </Button>
            </div>
          </div>

          <div className="img4container">
            <div
              style={{ backgroundImage: `url(${img1})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img2})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img3})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img4})` }}
              className="img4s"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(DermaPlanning_Service);
