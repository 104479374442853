/* eslint-disable react/prop-types */
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import React, { useState, useEffect } from "react";
import "./react-big-calendar.css";
import uuid from "uuid";
import { withFirebase } from "../app_state/FirebaseContext";
import EmployeeFilterCalender from "./EmployeeFilterCalender";
import EmployeeIndexs from "./EmployeeIndexes";
const localizer = momentLocalizer(moment);

/**
 * Calender data flow
 * > data comes from firebase hook
 * > goes to to a method name events
 * > goes to the state of the component
 * > optional event filter
 * 'Preet', 'Bonnie' , 'Kiran'
 */
const employees = [
  {
    name: "Bonnie",
    color: "#3174ad",
    id: uuid(),
  },
  {
    name: "Preet",
    color: "#F4A4AC",
    id: uuid(),
  },
  {
    name: "Kiran",
    color: "#CAADFF",
    id: uuid(),
  },
];

function Calender(props) {
  const today = new Date();

  const [monthFetchingOffset, setMonthFetchingOffset] = useState(2);
  const [employee, setEmployee] = useState("");
  const [eventsToDisplay, handleeventsToDisplay] = useState([
    {
      title: "All Day Event very long title",
      allDay: true,
      start: new Date(2020, 9, 7),
      end: new Date(2020, 9, 8),
      id: uuid(),
    },
  ]);

  // 'allDay' : false,
  //           'start' : new Date(selectedDate).toString(),
  //           'end': new Date(selectedEndDate).toString(),
  //           'epoch' : new Date(selectedDate).getTime().toString(),
  //           'id': uuid(),
  //           extra : {
  //               employee: 'any'
  //           }

  const events = (arrayOfEvents) => {
    if (arrayOfEvents) {
      const newEvents = arrayOfEvents.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      }));
      return newEvents;
    } else return [];
  };

  // this.props.firebase.appointments

  //  this.props.firebase.users().on('value', snapshot => {
  //   const usersObject = snapshot.val();
  //   const usersList = Object.keys(usersObject).map(key => ({
  //   ...usersObject[key],
  //   uid: key,
  //   }));

  useEffect(() => {
    props.firebase
      .freeAppointments()
      .orderByKey()
      .startAt(
        props.firebase.formatterHelperFunction(
          today.getFullYear(),
          today.getMonth()
        )
      )
      .limitToFirst(monthFetchingOffset)
      .on("value", (snapshot) => {
        console.log("coming from calender public");
        const val = snapshot.val();
        if (val) {
          const newevents = [];
          Object.keys(val).forEach((monthlykey) => {
            const thisMonthevents = val[monthlykey];
            Object.keys(thisMonthevents).forEach((key) => {
              newevents.push({ ...thisMonthevents[key] });
            });
          });
          // setter method is hanfleeventstodisplay and object formatting method is events
          handleeventsToDisplay(events(newevents));
        }
      });
    return () => {
      props.firebase.freeAppointments().off();
    };
  }, [monthFetchingOffset]);

  const handleUserTimeSlotClick = (timeSlotObject) => {
    if (timeSlotObject.extra.status) {
      return;
    } else {
      props.setActiveTime(timeSlotObject);
      props.handleNext();
    }
  };

  // eventPropGetter={() => ( {style : { backgroundColor : "red" } } )}
  //  end: Mon Dec 07 2020 10:00:36 GMT-0800 (Pacific Standard Time) {}
  //  epoch: 1607356836965
  //  eventkey: "202011/-MNqj7EMfsaIMxx0sWGt"
  //  extra: {employee: "any"}
  //  id: "e6183c67-96a4-48ff-b29b-b3ab92a195ed"
  //  start: Mon Dec 07 2020 08:00:36 GMT-0800 (Pacific Standard Time) {}
  //  title: "Appointment Available"
  const handleEventPropGetter = (eventObject) => {
    if (eventObject.extra.status) {
      return { style: { backgroundColor: "red" } };
    }

    if (eventObject.extra.employeeColor) {
      return { style: { backgroundColor: eventObject.extra.employeeColor } };
    }
  };
  /*
  This function validates the offset and if offset is more than the current offset is replaced with the new offset
  */
  const handleOffsetReview = (offset) => {
    offset = offset + 1;
    if (offset < 0) {
      return;
    } else if (offset < monthFetchingOffset) return;
    setMonthFetchingOffset(offset);
  };

  const handleOnNavigate = (dateRecieved) => {
    const v = new Date(dateRecieved);
    var offset = 0;
    const tempMonth = v.getMonth() + 1;
    const tempYear = v.getFullYear();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();
    if (currentYear === tempYear) {
      offset = tempMonth - currentMonth;
      if (offset > 0) {
        handleOffsetReview(offset);
        return;
      } else return;
    } else {
      if (tempYear > currentYear) {
        offset = 12 * (tempYear - currentYear) - currentMonth + tempMonth;
        handleOffsetReview(offset);
      } else return;
    }
  };

  const filterEventstoDisplay = () => {
    if (employee === "" || employee === "any") return eventsToDisplay;
    else {
      var index = -1;
      for (let i = 0; i < employees.length; i++) {
        if (employees[i].name === employee) {
          index = i;
        }
      }
      if (index === -1) {
        return eventsToDisplay;
      } else {
        return eventsToDisplay.filter(
          (event) => event.extra.employeeColor === employees[index].color
        );
      }
    }
  };
  return (
    <div className="calendar-container">
      <EmployeeFilterCalender employee={employee} setEmployee={setEmployee} />
      <EmployeeIndexs employees={employees} />

      <Calendar
        localizer={localizer}
        startAccessor="start"
        views={["month", "week", "day", "agenda"]}
        onNavigate={handleOnNavigate}
        endAccessor="end"
        eventPropGetter={handleEventPropGetter}
        events={filterEventstoDisplay()}
        defaultView="week"
        step={30}
        min={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9)
        }
        max={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 19)
        }
        onSelectEvent={handleUserTimeSlotClick}
        style={{ height: 1000 }}
      />
    </div>
  );
}

export default withFirebase(Calender);
