import React from "react";
import { TimeHoursMins } from "./Time";
import EOFacial from "./EOFacials";
import uuid from "uuid";
import ProductTitle from "./ProductTitle";

const infos = [
  {
    name: "Clear Skin Facial",
    info:
      "Perfect for that congested skin type needing a deep cleanse. Cucumber, Mangosteen, Kaolin Clay, Tea Tree Oil and Stone Crop are just some of the ingredients in this facial which help to detoxofy, tighten pores and calm inflamed acne prone skin",
    price: "$58",
    seed: new TimeHoursMins(60),
    uuid: uuid(),
  },
  {
    name: "Gentlemens Facial",
    info:
      "Targeting clogged pores and excess oil, the Gentlemens Facial will leave any skin type feeling fresh, bright and clear. Includes a feeling fresh, bright and clear. Includes a scalp and shoulder massage.",
    price: "$68",
    seed: new TimeHoursMins(60),
    uuid: uuid(),
  },
  {
    name: "Hydrating Facial",
    info:
      "A simple beautiful facial for all skin types. Stone Crop, a hardy and succulent plant, helps to lighten, brighten, hydrate and improve skins resiliency. Your skin will feel fresh, revived and perfectly hydrated.",
    price: "$72",
    seed: new TimeHoursMins(60),
    uuid: uuid(),
  },
  {
    name: "Illuminating Facial",
    info:
      "Full of natural, botanical and organic oils, extracts and acids. The Illuminating Facial has strong antioxidant and age - defying benefits helping to refine and even skin tone, restore elasticity, increase circulation and assist in hyper pigmentation. Our super facial!",
    price: "$85",
    seed: new TimeHoursMins(90),
    uuid: uuid(),
  },
];

export default function EminenseOrganicFacials() {
  const EOFS = infos.map((info) => <EOFacial key={uuid()} info={info} />);
  return (
    <div>
      <ProductTitle heading={"Eminense Organic Facials"} />

      <div className="handfoot-treatment-grid-parent">{EOFS}</div>
    </div>
  );
}
