import React from "react";
import { connect } from "react-redux";
import {
  addServiceForAppointment,
  removeServiceForAppointment,
} from "../app_state/actions";

function ValueIndividual(props) {
  const handleValueSelection = () => {
    props.addServiceForAppointment([{ ...props.info }]);
  };

  const handleValueDeSelection = () => {
    props.removeServiceForAppointment({ ...props.info });
  };

  const checkIfSelected = (info) => {
    for (var i = 0; i < props.appointments.length; i++) {
      if (props.appointments[i].uuid === info.uuid) {
        return false;
      }
    }

    return true;
  };

  if (checkIfSelected(props.info)) {
    return (
      <div onClick={handleValueSelection} className="product-values">
        {props.info.name}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            margin: ".5rem 0rem",
          }}
        >
          <span>{props.info.price}</span>
          <span>
            Time:{" "}
            <span style={{ color: "white" }}>
              {" "}
              {props.info.seed.toString()}{" "}
            </span>
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div onClick={handleValueDeSelection} className="product-values-selected">
        {props.info.name}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            margin: ".5rem 0rem",
          }}
        >
          <span>{props.info.price}</span>
          <span>Time: {props.info.seed.toString()}</span>
        </div>
      </div>
    );
  }
}

const mapThisComponentStateToProps = (state) => ({
  appointments: state.appointments,
});
export default connect(mapThisComponentStateToProps, {
  addServiceForAppointment: addServiceForAppointment,
  removeServiceForAppointment: removeServiceForAppointment,
})(ValueIndividual);
