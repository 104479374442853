import React from "react";
import { connect } from "react-redux";
import {
  addServiceForAppointment,
  removeServiceForAppointment,
} from "../app_state/actions";

function OrchidIndividualPacage(props) {
  const handleValueSelection = () => {
    props.addServiceForAppointment([props.info]);
  };
  const handleValueDeSelection = () => {
    props.removeServiceForAppointment(props.info);
  };
  const checkIfSelected = (info) => {
    for (var i = 0; i < props.appointments.length; i++) {
      if (props.appointments[i].uuid === info.uuid) return false;
    }
    return true;
  };

  if (checkIfSelected(props.info)) {
    return (
      <div
        onClick={handleValueSelection}
        className="handfoot-treatment-grid-childs"
      >
        <div className="product-title">{props.info.name}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            margin: "1.5rem 0rem",
          }}
        >
          <span>{props.info.price}</span>
          <span>
            Time:{" "}
            <span style={{ color: "white" }}>
              {" "}
              {props.info.seed.toString()}{" "}
            </span>
          </span>
        </div>

        <div className="orchid-package-description">
          {props.info.description}
        </div>
      </div>
    );
  } else {
    return (
      <div
        onClick={handleValueDeSelection}
        className="handfoot-treatment-grid-childs-selected"
      >
        <div className="product-title">{props.info.name}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            margin: "1.5rem 0rem",
          }}
        >
          <span>{props.info.price}</span>
          <span>Time: {props.info.seed.toString()}</span>
        </div>

        <div className="orchid-package-description">
          {props.info.description}
        </div>
      </div>
    );
  }
}

const mapThisComponentStateToProps = (state) => ({
  services: state.services,
  appointments: state.appointments,
});
export default connect(mapThisComponentStateToProps, {
  addServiceForAppointment: addServiceForAppointment,
  removeServiceForAppointment: removeServiceForAppointment,
})(OrchidIndividualPacage);
