import React from "react";
import MediaCard from "./MaterialuiCard";

const serviceNames = [
  "Micro Blading",
  "Facial Treatment",
  "Eminence Organic Facial",
  "Oxygeneo Facial",
  "Hydrafacial",
  "Hair Laser Treatment",
  "Cashmera Mask",
  "Microneedling",
  "Dermaplaning",
];

export default function CardServices() {
  const ServiceNames = serviceNames.map((serviceName, index) => (
    <MediaCard key={serviceName} index={index} serviceName={serviceName} />
  ));
  return (
    <div className="cardServices">
      <h1>Services We Offer</h1>

      <div className="cardServicesContainer">{ServiceNames}</div>
    </div>
  );
}
