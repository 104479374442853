/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
import classNames from "classnames";
import CrossButton from "./CrossButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const img1 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Foxyfacial.PNG?alt=media&token=1b082dbc-6a6d-47a0-a6e2-775d521dd1c4";
const img2 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Foxyfacial1.jpg?alt=media&token=170ab245-eb28-49de-a780-072c721364f8";
const img3 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Foxyfacial2.jpg?alt=media&token=ffa75510-7714-4b2e-9b82-6b796378c496";
const img4 =
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fmask4.jpg?alt=media&token=64205bdf-f419-49f7-a57c-84b89894a144";

function OxygeneoFacial(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, classes.mainRaised)}>
      <div
        style={{
          minHeight: "1000px",
        }}
      >
        <CrossButton />

        <Typography align="center" variant="h3" style={{ color: "#676504" }}>
          Oxygeneo Facial Treatment
        </Typography>

        <div className="mygrid2">
          <div
            style={{
              padding: "2rem",
              margin: "1rem",
              gridArea: "textformat",
            }}
          >
            <Typography variant="body1" style={{ color: "#676504" }}>
              The OxyGeneo ™ has earned the reputation as the #1 Facial
              Treatment in Canada for one simple reason… it works!
            </Typography>
            <Typography align="center" variant="h5" color="secondary">
              EXFOLIATE
            </Typography>
            <Typography variant="body1" style={{ color: "#676504" }}>
              The OxyGeneo ™ exfoliates the upper skin layer to remove dead
              cells, open cloggted pores, smooth the surface and renew the skin.
              The treatment generates an abundance of CO2 bubbles that permeate
              the upper skin layer to trigger a physiological response known as
              the Bohr Effect.
            </Typography>
            <Typography align="center" variant="h5" color="secondary">
              OXYGENATE
            </Typography>

            <Typography variant="body1" style={{ color: "#676504" }}>
              OxyGeneo™ harnesses the natural processes of the body to oxygenate
              the skin from within with the Bohr Effect! During the Bohr Effect,
              the body sends O2-rich blood to the skin to replace the CO2. This
              results in optimal skin oxygenation and increased cellular
              activity.
            </Typography>
            <Typography align="center" variant="h5" color="secondary">
              INFUSE
            </Typography>

            <Typography variant="body1" style={{ color: "#676504" }}>
              At the same time, OxyGeneo™ infuses nutrients, vitamins and
              selected active ingredients that nourish the skin and promote
              healthier and smoother complexion.
            </Typography>
            <div style={{ paddingTop: "2rem" }}>
              <Button
                onClick={() => props.history.push("/appointments")}
                variant="contained"
                color="secondary"
                endIcon={<ArrowRightAltIcon fontSize="large" />}
              >
                Book Now
              </Button>
            </div>
          </div>

          <div className="img4container">
            <div
              style={{ backgroundImage: `url(${img1})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img2})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img3})` }}
              className="img4s"
            ></div>
            <div
              style={{ backgroundImage: `url(${img4})` }}
              className="img4s"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(OxygeneoFacial);
