/* eslint-disable react/prop-types */
import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";

function CrossButton(props) {
  const handleClose = () => {
    props.history.push("/");
  };

  return (
    <div className="cancelfloatingright">
      <Tooltip title="Go Back" aria-label="go back">
        <IconButton onClick={handleClose} color="primary" component="span">
          <CancelIcon fontSize="large" color="secondary" />
        </IconButton>
      </Tooltip>
    </div>
  );
}
export default withRouter(CrossButton);
