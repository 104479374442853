import { compose, withProps } from "recompose";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import React from "react";

export const MyMapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: 49.05109, lng: -122.33043 }}
  >
    {props.isMarkerShown && (
      <Marker position={{ lat: 49.05109, lng: -122.33043 }} />
    )}
  </GoogleMap>
));
