/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import uuid from "uuid";
import {
  removeServiceForAppointment,
  resetAppointment,
} from "../app_state/actions";
import TextField from "@material-ui/core/TextField";
import classNames from "classnames";
import styles from "assets/jss/material-kit-react/views/components.js";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import Service from "./ServiceConfirmation";
import { TimeHoursMins } from "./Time";
import Spinner from "./Spinner";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";

//const FETCH_URL =
// "http://localhost:5000/orchid11medspa-6e432/us-central1/widgets/bookappointment";
const FETCH_URL =
  "https://us-central1-orchid11medspa-6e432.cloudfunctions.net/widgets/bookappointment";

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const SNACKBAR_CODES = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
};

function Confirmation(props) {
  const useStyles = makeStyles(styles);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [values, setValues] = React.useState("");
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [buttonDisabled, setbuttonDisabled] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  // success warning error info

  const handleClick = (msg, vr) => {
    enqueueSnackbar(msg, { variant: vr });
  };

  const handleClickForSendButton = () => {
    setLoading(true);
    handleClick("Booking your Appointment", SNACKBAR_CODES.INFO);
    fetch(FETCH_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        email: values,
        name: name,
        number: phone,
        selectedService: props.appointments,
        appointmentDetails: {
          ...props.activeTime,
          start: props.activeTime.start.toString(),
          end: props.activeTime.end.toString(),
        },
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setbuttonDisabled(true);
          handleClick(
            "Appointment booked Successfully",
            SNACKBAR_CODES.SUCCESS
          );
          handleMyReset();
        }
      })
      .catch((er) => {
        setbuttonDisabled(true);
        console.log(er, "er from confirmation");
        handleClick(
          "Sorry, Something went wrong. Please refer to the email for appointment confirmation",
          SNACKBAR_CODES.ERROR
        );
      });
  };
  const handleMyReset = () => {
    props.resetAppointment();

    setTimeout(() => {
      props.handleReset();
    }, 5000);
  };

  const services = props.appointments.map((appointment) => (
    <Service
      key={uuid()}
      removeServiceForAppointment={props.removeServiceForAppointment}
      info={appointment}
    />
  ));
  const getTotalPrice = () => {
    var temp = 0;
    for (var service of props.appointments) {
      const price = parseInt(service.price.replaceAll("$", ""));
      if (isNaN(price) === false) {
        temp += price;
      } else {
        throw new Error("Invalid number in Confirmation price");
      }
    }
    return temp;
  };

  const getTotalTime = () => {
    const totalTime = new TimeHoursMins(0);
    for (var service of props.appointments) {
      totalTime.addValueToSeed(service.seed.getValueInMins());
    }
    return totalTime.toString();
  };

  const myButtonOrSpinner = () => {
    if (!loading) {
      return (
        <Button
          disabled={buttonDisabled}
          onClick={handleClickForSendButton}
          variant="contained"
          color="primary"
        >
          Book{" "}
        </Button>
      );
    } else {
      return (
        <div style={{ margin: "auto" }}>
          <Spinner />
        </div>
      );
    }
  };

  return (
    <div
      className={classNames(
        classes.main,
        classes.mainRaised,
        classes.confirmationPage
      )}
    >
      <div
        style={{
          placeItems: "center",
          display: "grid",
        }}
      >
        <div>
          <ShoppingBasketIcon
            style={{ fontSize: "100", color: "#676504" }}
            color="primary"
          />
        </div>
      </div>
      <h3
        style={{
          textAlign: "center",
          margin: "2rem 0rem",
          color: "#676504",
        }}
      >
        Services Chosen{" "}
      </h3>{" "}
      {services}{" "}
      <h3 style={{ textAlign: "center", color: "#676504" }}>
        Appointment Chosen{" "}
      </h3>{" "}
      <Paper
        elevation={10}
        style={{
          margin: "1rem 0",
          backgroundColor: "lightsalmon",
          borderRadius: "10px",
          padding: "1rem",
        }}
      >
        Start time: {props.activeTime.start.toString().substring(0, 25)} <br />
        End time: {props.activeTime.end.toString().substring(0, 25)} <br />
        Total Time: {getTotalTime()} <br />
        Total Price: {getTotalPrice()} <br />
        Total Services: {props.appointments.length}
      </Paper>{" "}
      <div style={{ paddingTop: "1.5rem" }}>
        <TextField
          id="outlined-primary"
          label="Enter your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          variant="outlined"
        />
      </div>
      <div style={{ paddingTop: "1.5rem" }}>
        <TextField
          id="outlined-primary2"
          label="Enter your Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          variant="outlined"
        />
      </div>
      <div style={{ paddingTop: "1.5rem" }}>
        <TextField
          id="outlined-primary1"
          label="Enter your Email"
          value={values}
          onChange={(e) => setValues(e.target.value)}
          fullWidth
          variant="outlined"
          color={emailIsValid(values) ? "primary" : "secondary"}
        />{" "}
      </div>
      <div
        style={{
          placeItems: "center",
          margin: "1rem 0rem",
          display: "grid",
        }}
        className={classes.root}
      >
        {myButtonOrSpinner()}{" "}
      </div>
    </div>
  );
}

const mapThisComponentStateToProps = (state) => ({
  appointments: state.appointments,
});
export default connect(mapThisComponentStateToProps, {
  removeServiceForAppointment: removeServiceForAppointment,
  resetAppointment: resetAppointment,
})(Confirmation);
