import React from "react";
import ProductTitle from "./ProductTitle";
import OrchidIndividualPacage from "./OrchidIndividualPacage";
import { TimeHoursMins } from "./Time";
import uuid from "uuid";

const handFootTreatments = {
  title: "Hand & Foot Treatments",
  productsA: [
    {
      name: "Express Manicure",
      description: "Hail shaping, buff and polish",
      price: "$20",
      seed: new TimeHoursMins(25),
      uuid: uuid(),
    },
    {
      name: "Orchid Manicure",
      description: "Hand soak, nail shaping, cuticle care, massage and polish)",
      price: "$30",
      seed: new TimeHoursMins(45),
      uuid: uuid(),
    },
    {
      name: "Orchid Signature Manicure",
      description:
        "Hand soak, nail shaping,cuticle care, massage, paraffin and polish)",
      price: "$40",
      seed: new TimeHoursMins(60),
      uuid: uuid(),
    },

    {
      name: "Gel Polish Manicure",
      price: "$35",
      seed: new TimeHoursMins(30),
      description: "",
      uuid: uuid(),
    },
    {
      name: "Express Pedicure",
      description: "Nail shaping, buff and polish)",
      price: "$30",
      seed: new TimeHoursMins(30),
      uuid: uuid(),
    },
    {
      name: "Orchid Pedicure",
      description:
        "Foot soak, nail shaping, cuticle care, callous removal, massage and polish)",
      price: "$42",
      seed: new TimeHoursMins(60),
      uuid: uuid(),
    },
    {
      name: "Orchid Signature Pedicure",
      description:
        " Foot soak, nail shaping,cuticle care, massage, paraffin and polish)",
      price: "$50",
      seed: new TimeHoursMins(75),
      uuid: uuid(),
    },

    {
      name: "Gel Polish Pedicure",
      description: "",
      price: "$40",
      seed: new TimeHoursMins(90),
      uuid: uuid(),
    },
    {
      name: "Gel Polish Removal",
      description: "",
      price: "$30",
      seed: new TimeHoursMins(15),
      uuid: uuid(),
    },
    {
      name: "Nail Polish Change -$10",
      description: "",
      price: "$10",
      seed: new TimeHoursMins(30),
      uuid: uuid(),
    },
  ],
};

export default function HandFootTreatments() {
  const bts = handFootTreatments.productsA.map((hft) => (
    <OrchidIndividualPacage key={uuid()} info={hft} />
  ));

  return (
    <div className="handfoot-container">
      <ProductTitle heading={handFootTreatments.title} />

      <div className="handfoot-treatment-grid-parent">{bts}</div>
    </div>
  );
}
