import app from "firebase/app";
import "firebase/auth";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAOwLWRlBri5hOlvGec4BSlFJ8oYZIU-BY",
  authDomain: "orchid11medspa-6e432.firebaseapp.com",
  databaseURL: "https://orchid11medspa-6e432-default-rtdb.firebaseio.com",
  projectId: "orchid11medspa-6e432",
  storageBucket: "orchid11medspa-6e432.appspot.com",
  messagingSenderId: "711778663448",
  appId: "1:711778663448:web:c5455bbf732bbdcdb4176d",
  measurementId: "G-EJ5VSCZD9F",
};

class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(firebaseConfig);
    } else {
      app.app(); // if already initialized, use that one
    }
    this.auth = app.auth();
    this.db = app.database();
  }

  /** REALTIME DATABASE API */

  currentUser = () => this.db.ref("appointments");

  freeAppointments = () => this.db.ref("appointments/freeAppointments");
  // bookedAppointments = () => this.db.ref('appointments/bookedAppointments')

  formatterHelperFunction = (year, month) => {
    if (month < 10) {
      const newYear = year * 10;
      return `${newYear}${month}`;
    }
    return `${year}${month}`;
  };
}

export default Firebase;
