import React from "react";
import { connect } from "react-redux";
import {
  addServiceForAppointment,
  removeServiceForAppointment,
} from "../app_state/actions";
import Typography from "@material-ui/core/Typography";

function EOFacial(props) {
  const handleValueSelection = () => {
    props.addServiceForAppointment([props.info]);
  };

  const handleValueDeSelection = () => {
    props.removeServiceForAppointment(props.info);
  };
  const checkIfSelected = (name) => {
    for (var i = 0; i < props.appointments.length; i++) {
      if (props.appointments[i].name === name) return false;
    }
    return true;
  };

  if (checkIfSelected(props.info.name)) {
    return (
      <div onClick={handleValueSelection} className="product-values">
        <div className="product-title">{props.info.name}</div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            margin: "1.5rem 0rem",
          }}
        >
          <span>{props.info.price}</span>
          <span>
            Time:{" "}
            <span style={{ color: "white" }}>
              {" "}
              {props.info.seed.toString()}{" "}
            </span>
          </span>
        </div>

        <Typography variant="body2">{props.info.info}</Typography>
      </div>
    );
  } else {
    return (
      <div onClick={handleValueDeSelection} className="product-values-selected">
        <div className="product-title">{props.info.name}</div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            margin: "1.5rem 0rem",
          }}
        >
          <span>{props.info.price}</span>
          <span>Time: {props.info.seed.toString()}</span>
        </div>

        <Typography variant="body2">{props.info.info}</Typography>
      </div>
    );
  }
}

const mapThisComponentStateToProps = (state) => ({
  appointments: state.appointments,
});
export default connect(mapThisComponentStateToProps, {
  addServiceForAppointment: addServiceForAppointment,
  removeServiceForAppointment: removeServiceForAppointment,
})(EOFacial);
