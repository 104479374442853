/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const employeeSelection = ["Preet", "Bonnie", "Kiran"].map((empl, index) => (
  <MenuItem key={index * 2 + 1} value={empl}>
    {empl}
  </MenuItem>
));

export default function EmployeeFilterCalender(props) {
  const classes = useStyles();

  const handleChange = (event) => {
    props.setEmployee(event.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "2rem 0",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "1rem",
          borderRadius: "10px",
          boxShadow: "6px 5px 27px 6px rgba(30, 30, 60, 0.31)",
        }}
      >
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-helper-label">Employee</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={props.employee}
            onChange={handleChange}
          >
            <MenuItem value="any">
              <em>Any</em>
            </MenuItem>
            {employeeSelection}
          </Select>
          <FormHelperText>Select an employee to filter slots </FormHelperText>
        </FormControl>
      </div>
    </div>
  );
}
