/* eslint-disable react/prop-types */
import React from "react";
import Title from "./Title";
import Value from "./Value";
import ProductTitle from "./ProductTitle";
import uuid from "uuid";
import { TimeHoursMins } from "./Time";

const bodyTreatments = [
  {
    value: "Laser Hair Removal Soprano XL ",
    info: "Women ",
    product: [
      {
        name: "Face",
        price: "$50",
        seed: new TimeHoursMins(20),
        uuid: uuid(),
      },
      {
        name: "Underarms",
        price: "$30",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Chest & Stomach",
        price: "$100",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Brazilian",
        price: "$75",
        seed: new TimeHoursMins(20),
        uuid: uuid(),
      },
      {
        name: "Full Arms",
        price: "$150",
        seed: new TimeHoursMins(45),
        uuid: uuid(),
      },
      {
        name: "Bikini",
        price: "$45",
        seed: new TimeHoursMins(90),
        uuid: uuid(),
      },
      {
        name: "Full Legs",
        price: "$200",
        seed: new TimeHoursMins(60),
        uuid: uuid(),
      },
      {
        name: "Back",
        price: "$100",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Chin",
        price: "$15",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Upper Lip",
        price: "$15",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Full Body",
        price: "$350",
        seed: new TimeHoursMins(90),
        uuid: uuid(),
      },
    ],
  },
  {
    info: "Men",
    value: "Laser Hair Removal Soprano XL",
    product: [
      {
        name: "Cheeks",
        price: "$25",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Hairline",
        price: "$25",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Ears",
        price: "$15",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Chest & Stomach",
        price: "$100",
        seed: new TimeHoursMins(60),
        uuid: uuid(),
      },
      {
        name: "Shoulders",
        price: "$50",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Back",
        price: "$100",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Full Arms",
        price: "$150",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Upper Body",
        price: "$350",
        seed: new TimeHoursMins(60),
        uuid: uuid(),
      },
    ],
  },
  {
    value: "Waxing for Women",
    info: "Women",
    product: [
      {
        name: "Full Legs and Bikini",
        price: "$60",
        seed: new TimeHoursMins(60),
        uuid: uuid(),
      },
      {
        name: "Full legs",
        price: "$50",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Half Legs and Bikini",
        price: "$45",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Bikini",
        price: "$20",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Brazilian",
        price: "$35",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "French Bikini",
        price: "$30",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Full Arms",
        price: "$35",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Half Arms",
        price: "$25",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Underarms",
        price: "$20",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Lip or Chin",
        price: "$25",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Eyebrow",
        price: "$10",
        seed: new TimeHoursMins(15),
        uuid: uuid(),
      },
      {
        name: "Back",
        price: "$30",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
    ],
  },
  {
    value: "Waxing for Men",
    info: "Men",
    product: [
      {
        name: "Ears",
        price: "$15",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Brow",
        price: "$8",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Shoulder",
        price: "$15",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
      {
        name: "Chest / Stomach",
        price: "$15",
        seed: new TimeHoursMins(30),
        uuid: uuid(),
      },
    ],
  },
];

function BT(props) {
  return (
    <div className="body-treatment-individual-box">
      <div>
        <Title title={props.obj.value} />
        {props.obj.info ? (
          <div className="body-treatments-product-info"> {props.obj.info} </div>
        ) : (
          ""
        )}
        <Value value={props.obj.product} />
      </div>
    </div>
  );
}

export default function BodyTreatment() {
  const bts = bodyTreatments.map((bt) => <BT key={uuid()} obj={bt} />);

  return (
    <div className="facial-treatment-container">
      <ProductTitle heading="Body Treatments" />
      <div className="facial-treatment-grid-parent">{bts}</div>
    </div>
  );
}
