/* eslint-disable react/prop-types */
import React from "react";
import Title from "./Title";
import Value from "./Value";
import ProductTitle from "./ProductTitle";
import uuid from "uuid";
import { TimeHoursMins } from "./Time";

const facialTreatment = [
  {
    value: "Hydra Facial",
    product: [
      {
        name: "Hydra Facial (face & neck)",
        price: "$110",
        seed: new TimeHoursMins(60),
        uuid: uuid(),
      },
      {
        name: "Deluxe Hydra facialc(face,neck & dècolletè)",
        price: "$150",
        seed: new TimeHoursMins(60),
        uuid: uuid(),
      },
      {
        name: "Hydra Facial for men (with facial hair)",
        price: "$85",
        seed: new TimeHoursMins(60),
        uuid: uuid(),
      },
      {
        name: "Britenol Spot Corrector add on",
        price: "$15",
        seed: new TimeHoursMins(15),
        uuid: uuid(),
      },
      {
        name: "Derma Builder Age Refining add on",
        price: "$15",
        seed: new TimeHoursMins(15),
        uuid: uuid(),
      },
    ],
    offers: "Complimentary LED Light  Therapy",
  },
  {
    value: "Onygeneo 3-in-1 Super Facial ",
    product: [
      {
        name: "Neo-Bright Oxygeneo 3-in-1 Facial",
        price: "$115",
        seed: new TimeHoursMins(60),
        uuid: uuid(),
      },
      {
        name: "Neo-Revive Oxygeneo 3-in-1 Facial",
        price: "$115",
        seed: new TimeHoursMins(60),
        uuid: uuid(),
      },
    ],
    offers: "Complimentary Ultrasound*",
  },
  // requested by the client to remove
  // {
  //   value: "Dermalogica Facial",
  //   info:
  //     "All of our Dermalogica Facials include a full skin analysis and are customized to benifit your own current skin conditions",
  //   product: [
  //     {
  //       name: "Purifying",
  //       price: "$58",
  //       seed: new TimeHoursMins(90),
  //       uuid: uuid(),
  //     },
  //     {
  //       name: "Anti Ageing",
  //       price: "$75",
  //       seed: new TimeHoursMins(90),
  //       uuid: uuid(),
  //     },
  //     {
  //       name: "Hydrating",
  //       price: "$68",
  //       seed: new TimeHoursMins(90),
  //       uuid: uuid(),
  //     },
  //   ],
  // },
  {
    value: "Microblading",
    product: [
      {
        name: "Includes 4 week touchup",
        price: "$400",
        seed: new TimeHoursMins(90),
        uuid: uuid(),
      },
    ],
  },
  {
    value: "Micro needling -$300 per session",
    product: [
      {
        name: "Package of 3 sessions",
        price: "$750",
        seed: new TimeHoursMins(90),
        uuid: uuid(),
      },
    ],
  },
  {
    value: "Tinting",
    product: [
      {
        name: "Lash Tint",
        price: "$25",
        seed: new TimeHoursMins(90),
        uuid: uuid(),
      },
      {
        name: "Brow Tint",
        price: "$15",
        seed: new TimeHoursMins(90),
        uuid: uuid(),
      },
      {
        name: "Lash & Brow Tint",
        price: "$35",
        seed: new TimeHoursMins(90),
        uuid: uuid(),
      },
    ],
  },
  {
    value: "Threading",
    product: [
      {
        name: "Face",
        price: "$25",
        seed: new TimeHoursMins(90),
        uuid: uuid(),
      },
      {
        name: "Eyebrows",
        price: "$8",
        seed: new TimeHoursMins(90),
        uuid: uuid(),
      },
      {
        name: "Upper Lip",
        price: "$8",
        seed: new TimeHoursMins(90),
        uuid: uuid(),
      },
      {
        name: "Chin",
        price: "$5",
        seed: new TimeHoursMins(90),
        uuid: uuid(),
      },
    ],
  },
];

export default function FacialTreatment() {
  const fts = facialTreatment.map((ft) => <FT key={uuid()} obj={ft} />);

  return (
    <div>
      <ProductTitle heading="Facial Treatments" />
      <div className="facial-treatment-grid-parent">{fts}</div>
    </div>
  );
}

function FT(props) {
  return (
    <div className="facial-pack-individual-box">
      <div style={{ border: "0.5px solid #Dcd0bc", margin: "1rem" }}>
        <Title title={props.obj.value} />
        {props.obj.info ? (
          <div className="product-info"> {props.obj.info} </div>
        ) : (
          ""
        )}
        <Value value={props.obj.product} />
        {props.obj.offers ? (
          <div className="product-info"> {props.obj.offers} </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
