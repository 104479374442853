import React from "react";
import Carousel from "react-slick";
import useWindowSize from "./useWindowSize";

const images = [
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fcarousal1.jpg?alt=media&token=60a15858-ecba-4ce0-8229-2e32decd2467",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fcarousal11.PNG?alt=media&token=a4fdc06e-f630-4b2b-b3b7-c848854204e1",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2FcarousalT.jpg?alt=media&token=4a396389-e4f0-4e42-a569-44273db44fb9",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fcarousal3.JPG?alt=media&token=889eb265-8457-47ac-a18a-94024e8f12e0",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2Fone.jpg?alt=media&token=0b6fdc05-f8a2-4efe-99f8-7b5ab29d2323",
  "https://firebasestorage.googleapis.com/v0/b/orchid11medspa-6e432.appspot.com/o/images%2FcarousalT.jpg?alt=media&token=2c7a4ace-781c-4ddb-9ed7-433bf0ad2ec9",
];

const ASPECT_RATIO = 3 / 4;
const IMAGE_WIDTH_HEIGHT_ADJUST = 2;
const WIDTH_PICTURE = 500;
const HEIGHT_PICTURE = 375;

export default function Carousal() {
  const size = useWindowSize();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const getHeight = () => {
    if (size.width < 500) {
      return size.width;
    }
    return Math.max(
      HEIGHT_PICTURE,
      Math.floor((ASPECT_RATIO * size.width) / IMAGE_WIDTH_HEIGHT_ADJUST)
    );
  };

  const getWidth = () => {
    if (size.width < 500) {
      return size.width;
    }
    return Math.max(
      WIDTH_PICTURE,
      Math.floor(size.width / IMAGE_WIDTH_HEIGHT_ADJUST)
    );
  };

  const slickImages = images.map((image, index) => (
    <div key={index}>
      <img
        src={image}
        alt="orchid gallery"
        style={{
          width: getWidth(),
          height: getHeight(),
        }}
      />
    </div>
  ));
  // console.log(size, 'size');
  // console.log(getHeight(), 'height');
  // console.log(getWidth(), 'width');

  return (
    <div
      style={{
        width: getWidth(),
        height: getHeight(),
        margin: "auto",
        padding: "2rem",
      }}
    >
      <Carousel {...settings}>{slickImages}</Carousel>
    </div>
  );
}
