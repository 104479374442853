/* eslint-disable react/prop-types */
import React from "react";
import Button from "@material-ui/core/Button";

import Paper from "@material-ui/core/Paper";

export default function Service(props) {
  const handleClick = () => {
    props.removeServiceForAppointment(props.info);
  };
  return (
    <Paper
      elevation={10}
      style={{
        backgroundColor: "lightpink",
        padding: "1rem",
        margin: "1rem 0rem",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          margin: "0.5rem 0rem",
        }}
      >
        <div>{props.info.name}</div>

        <Button
          style={{ marginTop: "1rem" }}
          onClick={handleClick}
          variant="contained"
          color="secondary"
        >
          Remove
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          margin: "0.5rem 0rem",
        }}
      >
        <span>Price: {props.info.price}</span>
        <span>Time: {props.info.seed.toString()}</span>
      </div>
    </Paper>
  );
}
