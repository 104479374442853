import React from "react";
import uuid from "uuid";
import { TimeHoursMins } from "./Time";
import OrchidIndividualPacage from "./OrchidIndividualPacage";

const hui = [
  {
    name: "Relaxation Package",
    description: "Orchid Signature Manicure and Orchid Signature Pedicure",
    price: "$79",
    seed: new TimeHoursMins(120),
    uuid: uuid(),
  },
  {
    name: "Head 2 Toe Package",
    description: "1hr Relaxation Massage and Hydrating Facial",
    price: "$119",
    seed: new TimeHoursMins(120),
    uuid: uuid(),
  },
  {
    name: "Pamper Me Package (couples or friends)",
    description:
      "45 Min Relaxation Massage, Gel Polish Manicure and Orchid Pedicure",
    price: "$112",
    seed: new TimeHoursMins(180),
    uuid: uuid(),
  },
  {
    name: "You and Me Package",
    description: "Two 1hr Relaxation Massages and two Anti Ageing Facials",
    price: "$250",
    seed: new TimeHoursMins(120),
    uuid: uuid(),
  },
];

export default function OrchidPacage() {
  const OP = hui.map((op) => <OrchidIndividualPacage key={uuid()} info={op} />);

  return (
    <div className="orchid-container">
      <div className="product-title-heading">Orchid 11 Packages</div>
      <div className="valueContainer">{OP}</div>
    </div>
  );
}
